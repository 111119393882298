<template>
  <form
    ref="formUser"
    @submit.prevent="sendData"
    class="login-form d-flex flex-column"
    :class="type === 'register' ? 'border p-4 p-md-5' : 'p-md-4'"
  >
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Nombre" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Nombre'"
          v-model="v$.form.name.$model"
          required
        />
        <span class="text-danger" v-if="v$.form.name.$error"
          >El Nombre es requerido.</span
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Apellido" :required="true" class="fw-bold" />
        <BasicInputText
          v-model="v$.form.lastname.$model"
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Apellido'"
          required
        />
        <span class="text-danger" v-if="v$.form.lastname.$error"
          >El Apellido es requerido.</span
        >
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="RUT" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          v-model="v$.info.rut.$model"
          class="login-input mb-2"
          :placeholder="'RUT'"
          required
        />
        <span class="text-danger" v-if="v$.info.rut.$error">RUT no válido</span>
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel
          title="Correo Electrónico"
          :required="true"
          class="fw-bold"
        />
        <BasicInputText
          v-model="v$.form.email.$model"
          type="email"
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Correo Electrónico'"
          required
          :disabled="
            $route.name === 'admin-users-edit'
              ? false
              : form.role && form.role !== 'admin'
          "
        />
        <span class="text-danger" v-if="v$.form.email.$error"
          >El Correo electrónico tiene un formato invalido.</span
        >
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Teléfono" :required="true" class="fw-bold" />
        <BasicInputText
          v-model="info.phone"
          :hasRoundedCorners="true"
          class="login-input"
          :placeholder="'Teléfono'"
        />
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Comuna" :required="true" class="fw-bold" />
        <BasicSelectBox
          name="comune"
          class="form-control mt-3"
          defaultValueText="Seleccione una comuna"
          :options="comunas"
          v-model="v$.info.comuna_id.$model"
        />
        <span class="text-danger" v-if="v$.info.comuna_id.$error"
          >La Comuna es requerida.</span
        >
      </div>
    </div>

    <div class="mt-4 col-md-12">
      <BasicLabel title="Dirección" :required="true" class="fw-bold" />
      <BasicInputText
        :hasRoundedCorners="true"
        class="mt-2 mb-2"
        :placeholder="'Dirección'"
        v-model="v$.info.address.$model"
        required
      />
      <span class="text-danger" v-if="v$.info.address.$error"
        >La Dirección es requerida.</span
      >
    </div>

    <div class="row mt-4">
      <div class="col-md-6 col-sm-12">
        <BasicLabel
          title="Contraseña"
          :required="
            type === 'editar'
              ? form.password ||
                form.password_confirmation ||
                (form.password === '' && form.password_confirmation === '')
              : false
          "
          class="fw-bold"
        />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.form.password.$model"
          type="password"
          :required="
            type === 'editar'
              ? form.password ||
                form.password_confirmation ||
                (form.password === '' && form.password_confirmation === '')
              : false
          "
          :placeholder="'Contraseña'"
        />
        <span class="text-danger" v-if="v$.form.password.$error"
          >La Contraseña es requerida y debe tener al menos 6 caracteres.</span
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel
          title="Repita su contraseña"
          :required="
            type === 'editar'
              ? form.password ||
                form.password_confirmation ||
                (form.password === '' && form.password_confirmation === '')
              : false
          "
          class="fw-bold"
        />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          type="password"
          v-model="v$.form.password_confirmation.$model"
          :required="
            type === 'editar'
              ? form.password ||
                form.password_confirmation ||
                (form.password === '' && form.password_confirmation === '')
              : false
          "
          :placeholder="'Repita su contraseña'"
        />
        <span class="text-danger" v-if="v$.form.password_confirmation.$error"
          >Las contraseñas no coinciden.</span
        >
      </div>
    </div>

    <div class="form-check my-5" v-if="type === 'register'">
      <span class="">
        ¿Acepta los
        <link-wrapper
          :location="'/terms-and-conditions'"
          class="fs-6 text-primary"
          style="cursor: pointer"
          target="_blank"
          :external="true"
        >
          <span>términos y condiciones</span>
        </link-wrapper>
        ?</span
      >
      <input
        class="form-check-input mb-2"
        type="checkbox"
        id="flexCheckVendor"
        name="flexCheckVendor"
        v-model="acept_terms"
      />
    </div>

    <div
      class="d-flex"
      :class="
        type === 'register'
          ? 'justify-content-between'
          : 'justify-content-end mt-4'
      "
    >
      <p class="mb-0" v-if="type === 'register'">
        ¿Ya tienes una cuenta?
        <button-outline
          type="button"
          class="ml-3"
          @click="$router.push({ name: 'MyAccount' })"
          :hasRoundedCorners="true"
          size="small"
          >Ingresa</button-outline
        >
      </p>
      <ButtonFilled
        type="submit"
        class="Minicart_button text-white"
        :text="type === 'register' ? 'Registrarse' : 'Actualizar'"
        :hasRoundedCorners="true"
      />
    </div>
  </form>
</template>

<script>
import axios from "axios";
import ButtonFilled from "../../atoms/Button/ButtonFilled.vue";
import BasicInputText from "../../atoms/FormInput/BasicInputText.vue";
import BasicLabel from "../../atoms/FormInput/BasicLabel.vue";
import BasicSelectBox from "../../atoms/FormInput/BasicSelectBox.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs, minLength } from "@vuelidate/validators";
import { validateRut } from "@/utils";
import { mapGetters, mapActions } from "vuex";
import LinkWrapper from "../../atoms/LinkWrapper/LinkWrapper.vue";
import ButtonOutline from "../../atoms/Button/ButtonOutline.vue";

export default {
  props: {
    userId: { default: null },
    type: {
      type: String,
      default: "register",
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    this.fetchComunas();
  },
  created() {
    if (this.userId && this.type === "editar") {
      this.setUser();
    }
  },
  data() {
    return {
      form: {
        name: null,
        lastname: null,
        email: null,
        password: null,
        password_confirmation: null,
        role: null,
      },
      // Data User Info
      info: {
        phone: null,
        address: null,
        comuna_id: null,
        rut: null,
      },
      acept_terms: false,
    };
  },
  components: {
    ButtonFilled,
    BasicInputText,
    BasicLabel,
    BasicSelectBox,
    LinkWrapper,
    ButtonOutline,
  },
  computed: {
    ...mapGetters({
      comunas: "tool/comunas",
    }),
  },
  methods: {
    ...mapActions({
      fetchComunas: "tool/fetchComunas",
      sendRegisterUser: "auth/registerUser",
      updateUser: "userApp/updateUser",
    }),
    async sendData() {
      if (this.type === "register" && !this.acept_terms) {
        return this.$swal({
          icon: "info",
          title: "Atención",
          text: "Debes aceptar los términos y condiciones",
        });
      }
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        let loader = this.$loading.show();
        const resp =
          this.type === "editar"
            ? await this.updateUser({
                user: this.form,
                info: this.info,
                userId: this.userId,
              })
            : await this.sendRegisterUser({
                user: this.form,
                info: this.info,
              });
        if (resp?.message) {
          loader.hide();
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: resp.message,
            showConfirmButton: true,
          }).then(async () => {
            let url = "admin-users-show";
            if (this.type === "editar") {
              if (this.$route.name === "EditProfile") url = "profile";
            } else url = "auth-login";
            await this.$store.dispatch("auth/fetchUser");
            if (this.type === "editar") {
              this.$router.push({
                name: url,
                params: { id: this.type === "editar" ? this.userId : null },
              });
            } else {
              this.$router.push({
                name: "MyAccount",
              });
            }
          });
        }
      }
    },
    async setUser() {
      try {
        let loader = this.$loading.show();
        const { data } = (await axios.get(`/api/users/${this.userId}`)).data;
        this.form = data.attributes;
        this.form.password_confirmation = null;
        if (data.relationships.info) this.info = data.relationships.info;
        else {
          this.info = {
            phone: null,
            address: null,
            comuna_id: null,
            rut: null,
          };
        }

        // Validate Admin
        // if (this.user.role !== "admin") this.setRules();

        loader.hide();
      } catch (e) {
        console.log(e);
      }
    },
  },
  validations() {
    if (this.form.password || this.form.password_confirmation) {
      return {
        form: {
          name: { required },
          lastname: { required },
          email: { required, email },
          password: { required, minLengthValue: minLength(6) },
          password_confirmation: {
            sameAsPassword: sameAs(this.form.password),
          },
        },
        info: {
          rut: { required, validateRut },
          comuna_id: { required },
          address: { required },
        },
      };
    } else {
      return {
        form: {
          name: { required },
          lastname: { required },
          email: { required, email },
          password: {},
          password_confirmation: {},
        },
        info: {
          rut: { required, validateRut },
          comuna_id: { required },
          address: { required },
        },
      };
    }
  },
};
</script>
<style>
.customer-checkbox {
  margin-top: 10px;
}
.login-input {
  height: 40px !important;
  padding: 10px 20px !important;
  box-shadow: none !important;
  margin-top: 10px;
}
.form-check {
  padding-left: 27px !important;
}
.login-field,
.login-field-last {
  margin-top: 30px !important;
}

.Minicart_button {
  width: 150px !important;
  height: 40px !important;
}
@media screen and (max-width: 768px) {
  .login-input {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .login-input {
    width: 250px !important;
  }
  .form-check-input input {
    width: 15px !important;
  }
}
</style>
