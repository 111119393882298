<template>
  <div class="card p-0">
    <section class="card-header pl-5 text-white w-100 p-3">
      <slot name="title"></slot>
    </section>
    <section class="card-body">
      <slot name="body"></slot>
    </section>
    <section class="card-footer-secondary">
      <slot name="footer"></slot>
    </section>
  </div>
</template>
<script>
export default {
  computed: {},
};
</script>
<style scoped="scoped">
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 16px;
  background-color: white;
  width: 100%;
}
.card-footer-secondary {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}
.card-header {
  background-color: var(--primary-color);
}
@media (max-width: 767.98px) {
  .card {
    flex-wrap: wrap;
    padding: 40px !important;
    width: calc(100% - 40px);
    margin: 0 20px;
  }
}
</style>
