<template>
  <select
    style="border-radius: 1rem"
    :value="modelValue"
    @input="onInput"
    v-if="options.length > 0"
    :multiple="multiple"
    :placeholder="defaultValueText"
    :disabled="disabled"
  >
    <option disabled value="">{{ defaultValueText }}</option>
    <template v-for="option in options" :key="option">
      <option :value="option.id">{{ option.name }}</option>
    </template>
  </select>
</template>

<script>
import { has } from "lodash";

export default {
  props: {
    modelValue: {
      default: null,
    },
    multiple: {
      default: false,
    },
    disabled: {
      default: false,
    },
    options: {
      type: Array,
      validator(values) {
        let valid = true;
        for (let value of values) {
          if (has(value, "name", "id") === false) {
            valid = false;
            break;
          }
        }
        return valid;
      },
      required: true,
    },
    defaultValueText: {
      type: String,
      default: "Select an item of the list",
      required: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>
